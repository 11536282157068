import { template as template_30e5a642fb9c426592270a35b90c7aef } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_30e5a642fb9c426592270a35b90c7aef(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
