import { template as template_8554543dfb8e4cfc843ef688406bc0b1 } from "@ember/template-compiler";
const FKText = template_8554543dfb8e4cfc843ef688406bc0b1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
