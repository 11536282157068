import { template as template_72dd0d901d704b23946b83d65c9fe34d } from "@ember/template-compiler";
const WelcomeHeader = template_72dd0d901d704b23946b83d65c9fe34d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
